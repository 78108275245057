/*****************************
**********Login page**********
*****************************/
body {
    background-image: url('../assets/images/DryBG@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
.login {
    background-image: url('../assets/images/DryBG@2x.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh
}

.form-box {
    height: 450px;
    background-color: #FFFFFF;
}

.form-box .heading {
    color: #2B5134;
    font-size: 25px;
    font-weight: 700;
    /* font-family: Helvetica; */
}

.user-icon {
    left: 160px;
}

.input-area {
    top: 210px;
    left: 235px;
}

.input-area input {
    width: 330px;
    height: 40px;
}

.input-area input::placeholder {
    padding-left: 30px;
    color: #2B5134;
}

.sign-in {
    bottom: 60px;
    left: 350px;
    font-size: 25px;
    color: #2B5134;
    font-weight: 700;
    cursor: pointer;
}

/*****************************
*********Header page**********
*****************************/

.header {
    background-color: #fff;
    width: 100%;
}

.header-text {
    font-size: 42px;
    font-weight: 700;
    color: #2B5134;
    right: 40px;
    top: 20px;
}

.navlink {
    color: #2B5134;
    padding: 0 30px;
    font-size: 20px;
    font-weight: 500;
}

/*****************************
********Airdrop page**********
*****************************/

.airdrop {
    height: 100vh;
    padding: 30px 0;
}

.nft-info {
    min-height: 93vh;
    margin: 0 30px 0;
    background-color: #FFFFFF;
    border-radius: 40px;
    padding: 40px;
}

.nft-type, .quantity {
    color: #2B5134;
    font-weight: 700;
    font-size: 23px;
}

.nft-meta p, .nft-info-meta p {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
}

.input-address textarea {
    padding: 15px 20px;
    font-size: 18px;
    /* width: 480px; */
    height: 560px;
}

.input-address textarea::placeholder {
    color: #2B5134;
}

.airdrop-button {
    position: absolute;
    right: 20px;
    bottom: -70px;
    padding: 5px 30px;
    background-color: black;
    color: white;
    font-size: 23px;
    font-weight: 700;
    border-radius: 30px;
    cursor: pointer;
}

.nft-info-meta p {
    margin-left: 30px;
}

.nft-info-meta {
    margin-top: 175px;
}

.address-sequence {
    color: #2B5134;
    font-size: 16px;
}

.address-sequence span {
    font-weight: 600;

}

.address-sequence p{
    line-height: normal;
    padding: 0;
    margin-bottom: 10px;
}

.common-button {
    position: absolute;
    right: 0px;
    padding: 4px 25px;
    background-color: white;
    color: #2B5134;
    font-size: 23px;
    font-weight: 700;
    border-radius: 30px;
    border: 1px solid black;
}

.claimed {
    top: -40px;
    cursor: pointer;
}

.medal {
    width: 210px;
    padding: 0;
    text-align: center;
    padding: 4px;
    cursor: pointer;
}

.medal:nth-of-type(2) {
    top: 95px;
}

.medal:nth-of-type(3) {
    top: 150px;
}

.medal:nth-of-type(4) {
    top: 40px;
}

.global-change {
    position: absolute;
    font-size: 21px;
    font-weight: 700;
    color: red;
    top: -70px;
}

.update {
    top: 210px;
    background-color: #2B5134;
    color: white;
    width: 210px;
    padding: 0;
    text-align: center;
    padding: 4px;
    cursor: pointer;
}

.yellow-bg {
    background-color: #F5D64B;
}

.nft-modal {
    display: none;
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    background-color: transparent;
    opacity: 0.5;
}

.nft-modal .form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 400px;
    background-color: red;
}

.owned div {
    display: inline-block;
}

.owned .medal {
    margin-top: 40px;
    position: unset;
    height: 43px;
}

.disable-pointer {
    pointer-events: none;
}
@media (max-width: 1480px) {
    .input-address textarea {
        margin-left: 3rem!important;
        max-width: 295px;
        max-height: 500px;
    }
}

.page-link {
    border-radius: 0% !important;
}

.visually-hidden {
    display: none;
}